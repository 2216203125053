// Trick VH for mobile Devices
let vh = window.innerHeight * 0.01;

let setVHSize = () => {
  let vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
}

document.addEventListener('DOMContentLoaded', setVHSize);
window.addEventListener('resize', setVHSize);


// Navigation
document.addEventListener('DOMContentLoaded', () => {
  document.querySelector('.headerbar__navtoggler').addEventListener('click', () => {
    if (window.innerWidth < 992) {
      document.body.classList.toggle('body--activenav');
    }
  });
})

// Headline Trasnform Landingpage

let setHeadingHeight = () => {
  if (document.querySelector('.com-content-article__body-headline')) {
    let headingheight = document.querySelector('.com-content-article__body-headline').clientHeight;
    document.documentElement.style.setProperty('--headingheight', `${headingheight}px`);
  }
}
document.addEventListener('DOMContentLoaded', () => {
  setTimeout(setHeadingHeight, 50);
});
window.addEventListener('resize', setHeadingHeight);

// Contentsection Appendix

let addContentsectionAppendix = () => {
  if (document.querySelector('[class*="contentsection bg--"]')) {
    for (let contentsection of document.querySelectorAll('[class*="contentsection bg--"]')) {
      const box = document.createElement("div");
      box.classList.add('contentsectionappendix')
      contentsection.appendChild(box);
    }
  }
}

document.addEventListener('DOMContentLoaded', addContentsectionAppendix);

let initializeAOS = () => {
  AOS.init({
    duration: 500,
    easing: 'ease-in-out-quart',
    once: true,
    anchorPlacement: 'top-bottom'
  });
}
window.setTimeout(initializeAOS,5);